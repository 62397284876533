<template>
  <div class="label-group-container">
    <el-card style="width: 100%" shadow="hover">
      <p slot="title">自定义学科标签集</p>
      <p slot="extra" @click="deleteFieldStarted(idx)"></p>
      <el-button type="danger" @click="handleLevelTop()">添加考察维度</el-button>
      <div v-for="(item, idx) in resultArr" :key="idx" style="margin-top:20px">
        <label-level
          :items="resultArr[idx]"
          @change-label="changeLable"
          @remove-child="removeChild"
          @label-child="labelChild"
          @verification_flag="verificationFlag"
          :new_validate_s="new_validate_s"
          ref="childRules"
        ></label-level>
      </div>
    </el-card>
  </div>
</template>



<script>
import LabelLevel from "../../components/LabelLevel";

export default {
  name: "LabelGroup",
  props: {
    arr: {
      type: Array,
      default: () => [],
    },
    new_validate: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      resultArr: [],
      new_validate_s: []
    };
  },
  computed: {

  },
  components: {
    LabelLevel,
  },
  watch: {
    arr: {
      handler () {
        this.resultArr = this.arr;
      },
      deep: true,
    },
    new_validate: {
      handler () {
        this.new_validate_s = this.new_validate
      },
      deep: true,
    },
    resultArr: {
      handler () {
      },
      deep: true,
    },
  },
  methods: {
    verificationFlag (val) {
      this.$emit('verification_father', val)
    },
    labelChild (val, index) {
    },
    init () {
    },
    removeChild (child, depth) {
      let idx = this.resultArr.findIndex(x => x === child);
      this.resultArr.splice(idx, 1);
    },
    addLable (type, idx) {
      let obj = {
        child_label: [],
        extendContent: [], // 选项列表
        id: null,
        labelType: null,
        leafNodeFlag: null,
        lengthLimit: null,
        name: "",
        numberLowerLimit: null,
        numberSupperLimit: null,
        remark: "", // 暂无用
        status: 0, // 暂无用
        switch_value: false
      };
      switch (type) {
        case 0:
          this.resultArr.push(obj);
          break;
        case 1:
          (obj.labelType = 0), (obj.leafNodeFlag = false);
          this.resultArr[idx].child_label.push(obj);
          break;
        case 2:
          obj.leafNodeFlag = true;
          break;
      }
    },
    changeLable (payload, depth, idx) {
      if (depth == 0) {
      }
      this.$emit("change-final-label", this.resultArr);
    },
    handleLevelTop () {
      let obj = {
        child_label: [],
        extendContent: [], // 选项列表
        id: null,
        labelType: 0,
        leafNodeFlag: null,
        lengthLimit: null,
        name: "",
        numberLowerLimit: null,
        numberSupperLimit: null,
        remark: "", // 暂无用
        status: 0, // 暂无用
        switch_value: false
      };
      this.resultArr.push(obj);
    },
  },
  created () { },
  mounted () {
    this.init();
  },
};
</script>

<style lang='less' scoped>
@import '../../style/mouse.less';
</style>