<template>
  <div class="label-item-container">
    <el-form :model="item">
      <el-form-item label="标签名称：">
        <el-input style="width: 400px" v-model="item.name" type="text" placeholder="（必填）" autofocus></el-input>
      </el-form-item>
      <el-form-item label="标签类型：">
        <el-radio-group v-model="item.labelType" @on-change="changeLabelType">
          <el-radio label="1">
            <span>数值型</span>
          </el-radio>
          <el-radio label="2">
            <span>单选型</span>
          </el-radio>
          <el-radio label="3">
            <span>多选型</span>
          </el-radio>
          <el-radio label="4">
            <span>文本型</span>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="数值范围限制：" v-if="item.labelType == 1">
        <el-input
          v-model="item.numberLowerLimit"
          type="number"
          placeholder="请输入最小值"
          style="width: 200px;margin-right: 20px"
          maxlength="5"
        />
        <el-input
          v-model="item.numberSupperLimit"
          type="number"
          placeholder="请输入最大值"
          style="width: 200px"
          maxlength="5"
        />
      </el-form-item>
      <el-form-item label="标签可选值：" v-if="item.labelType == 2 || item.labelType == 3">
        <el-input
          style="width: 500px"
          v-model="item.strExtendContent"
          type="text"
          placeholder="（必填）请用英文逗号（,）来分隔各个选项值，例如：客观题,主观题,复合题..."
          autofocus
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>



<script>
export default {
  name: "LabelItem",
  props: {
    data: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {};
  },
  components: {},
  watch: {
    item: {
      handler () {
      },
      deep: true
    }
  },
  computed: {
    item () {
      return this.data;
    },
  },
  methods: {
    changeLabelType (e) {
    },
  },
  created () { },
  mounted () { },
};
</script>

<style lang='less' scoped>
@import '../../style/mouse.less';
</style>