<template>
  <div class="label-level-container">
    <el-form :class="`class-${depth}`" :model="items" :rules="rules_s" ref="items">
      <el-form-item :label="levelText" prop="name">
        <el-input
          style="width: 200px; margin-right: 20px"
          v-model="items.name"
          type="text"
          placeholder="（必填）例：客观属性"
          autofocus
          maxlength="20"
          show-word-limit
        ></el-input>
        <el-button style="margin-right: 20px" type="success" @click="addLable(2)">添加标签</el-button>
        <el-button type="warning" @click="addLable(1)" v-if="curDepth < maxDepth">添加子维度</el-button>
        <div>
          是否必填:
          <el-switch
            v-model="items.switch_value"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="change_switch(items)"
          ></el-switch>
        </div>

        <p class="el-icon-close" @click="removeSelf()"></p>
        <label-level
          v-for="(item, idx) in children"
          :key="'lv'+idx"
          :items="item"
          :depth="1 + curDepth"
          @change-label="changeLable"
          @remove-child="removeChild"
        ></label-level>
      </el-form-item>
      <div class="label-list">
        <div
          class="label-ui"
          :class="`class-${depth}`"
          v-for="(lab,index) in labels"
          :key="'lab'+index"
        >
          <el-form-item :label="lab.labelTypeText">
            <template v-if="lab.labelType == 1">
              <el-input style="width: 200px; margin-right: 20px" v-model="lab.name" type="text"></el-input>
              <span>数值范围</span>
              <el-input-number
                style="width: 150px; margin: 0 20px"
                v-model="lab.numberLowerLimit"
                type="number"
                controls-position="right"
                :max="lab.numberSupperLimit-1"
              ></el-input-number>
              <span>~</span>
              <el-input-number
                style="width: 150px; margin: 0 20px"
                v-model="lab.numberSupperLimit"
                type="number"
                controls-position="right"
                :min="lab.numberLowerLimit-0+1"
              ></el-input-number>
            </template>
            <template v-if="lab.labelType == 2">
              <el-input style="width: 200px; margin-right: 20px" v-model="lab.name" type="text"></el-input>
              <span>单选选项</span>
              <el-input
                style="width: 300px; margin: 0 20px"
                v-model="lab.strExtendContent"
                type="text"
              ></el-input>
            </template>
            <template v-if="lab.labelType == 3">
              <el-input style="width: 200px; margin-right: 20px" v-model="lab.name" type="text"></el-input>
              <span>多选选项</span>
              <el-input
                style="width: 300px; margin: 0 20px"
                v-model="lab.strExtendContent"
                type="text"
              ></el-input>
            </template>
            <template v-if="lab.labelType == 4">
              <el-input
                style="width: 200px; margin-right: 20px"
                v-model="lab.name"
                type="text"
                maxlength="20"
                show-word-limit
              ></el-input>
            </template>
            <p class="el-icon-close" @click="removeLabel(lab)"></p>
          </el-form-item>
        </div>
      </div>
      <el-dialog :visible.sync="labelModal" width="700" title="标签选择">
        <div>
          <el-form :model="LabelItem" :rules="rules_window" ref="LabelItem">
            <el-form-item label="标签名称：" prop="name">
              <el-input
                style="width: 400px"
                v-model="LabelItem.name"
                type="text"
                placeholder="（必填）"
                autofocus
                size="mini"
                maxlength="20"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="标签类型：" prop="labelType">
              <el-radio-group v-model="LabelItem.labelType">
                <el-radio :label="1">
                  <span>数值型</span>
                </el-radio>
                <el-radio :label="2">
                  <span>单选型</span>
                </el-radio>
                <el-radio :label="3">
                  <span>多选型</span>
                </el-radio>
                <el-radio :label="4">
                  <span>文本型</span>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="数值范围限制："
              v-if="LabelItem.labelType == 1"
              prop="numberLowerLimit"
              class="el-form-item-max"
            >
              <el-input
                style="width: 150px; margin: 0 20px"
                v-model="LabelItem.numberLowerLimit"
                placeholder="请输入最小值"
                type="number"
                controls-position="right"
                size="mini"
              ></el-input>
              <el-form-item prop="numberSupperLimit">
                <el-input
                  style="width: 150px; margin: 0 20px"
                  v-model="LabelItem.numberSupperLimit"
                  placeholder="请输入最大值"
                  type="number"
                  controls-position="right"
                  size="mini"
                ></el-input>
              </el-form-item>
            </el-form-item>
            <el-form-item
              label="标签可选值："
              prop="strExtendContent"
              v-if="LabelItem.labelType == 2|| LabelItem.labelType == 3"
            >
              <el-input
                style="width: 500px"
                v-model="LabelItem.strExtendContent"
                type="text"
                placeholder="（必填）请用英文逗号（,）来分隔各个选项值，例如：客观题,主观题,复合题..."
                autofocus
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer">
          <el-button
            size="large"
            @click="
              labelModal = false;
              curFieldIdx = '';
            "
          >取消</el-button>
          <el-button type="primary" size="large" @click="addLabelItemExcuted('LabelItem')">确定</el-button>
        </div>
      </el-dialog>
    </el-form>
  </div>
</template>



<script>
import LabelItem from "../../components/LabelItem";
export default {
  name: "LabelLevel",
  props: {
    items: {
      type: Object,
      default: () => { },
    },
    new_validate_s: {
      type: Array,
      default: () => [],
    },
    depth: {
      // 0开始
      type: Number,
      default: 0,
    },
    maxDepth: {
      // 0开始
      type: Number,
      default: 2,
    },
  },
  data () {
    return {
      rules_s: {},
      rules_window: {
        name: [
          { required: true, message: '请输入标签名称', trigger: 'blur' },
        ],
        labelType: [
          { required: true, message: '请选择标签类型', trigger: 'change' }
        ],
        numberLowerLimit: [
          { required: true, message: '请填写最小值', trigger: 'blur' }
        ],
        numberSupperLimit: [
          { required: true, message: '请填写最大值', trigger: 'blur' }
        ],
        strExtendContent: [
          { required: true, message: '请填写标签可选值', trigger: 'blur' }
        ]
      },
      obj: { result: this.items },
      children: [],
      labels: [],
      LabelItem: {}, // 标签元对象
      curDepth: this.depth,
      labelModal: false,
      curIdx: null,
      labelData: {},
    };
  },
  watch: {
    items: {
      handler () {
        this.obj.result = this.items
        // 10.29（回显单选多选无法编辑）
        this.getDiffData();
      },
      deep: true,
    },
    new_validate_s: {
      handler () {
        this.verification()
      },
      deep: true,
    }
  },
  computed: {
    levelText () {
      return `${this.curDepth + 1}级维度名称：`;
    },
  },
  components: {
    LabelItem,
  },

  methods: {
    change_switch (val) {
      val = this.check_switch(val)
    },
    removeSelf () {
      this.$confirm('此操作将永久删除该维度, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit("remove-child", this.items, this.curDepth);
        this.getDiffData()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 验证
    verification () {
      let flag = null
      this.$refs['items'].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          flag = false
        }
      });
      this.$emit('verification_flag', flag)
      return flag
    },
    removeChild (child, depth) {
      let idx = this.items.child_label.findIndex(x => x === child);
      this.items.child_label.splice(idx, 1);
    },
    init () {
      this.getDiffData();
    },
    getDiffData () {
      if (this.obj.result) {
        this.children = this.obj.result.child_label.filter((item) => item.labelType == 0);
        this.labels = this.obj.result.child_label.filter((item) => item.labelType != 0);
      }
      this.$emit("change-label", this.items, this.curDepth);
    },
    addLable (type) {
      let obj = {
        child_label: [],
        code: null,
        extendContent: [], // 选项列表
        id: null,
        labelType: null,
        leafNodeFlag: null,
        lengthLimit: null,
        name: "",
        numberLowerLimit: null,
        numberSupperLimit: null,
        remark: "", // 暂无用
        status: 0, // 暂无用
        switch_value: false
      };
      switch (type) {
        case 1:
          (obj.labelType = 0), (obj.leafNodeFlag = false);
          if (this.curDepth < this.maxDepth) {
            obj.switch_value = this.items.switch_value
            this.items.child_label.push(obj);
            this.getDiffData();
          }
          this.$emit("change-label", this.items, this.curDepth);
          break;
        case 2:
          this.labelModal = true;
          obj.labelType = 1;
          obj.leafNodeFlag = true;
          obj.strExtendContent = "";
          this.LabelItem = obj;
          this.$emit("change-label", this.items, this.curDepth);
          break;
      }
    },
    addLabelItemExcuted (formName) {
      let numState = true
      this.$refs[formName].validate((valid) => {
        if (this.LabelItem.labelType == 1 && Number(this.LabelItem.numberLowerLimit) >= Number(this.LabelItem.numberSupperLimit)) {
          numState = false
          this.$message({
            message: '最大值不可小于等于最小值!',
            type: 'warning'
          });
        } else {
          numState = true
        }
        if (valid && numState) {
          this.LabelItem.labelType = parseInt(this.LabelItem.labelType);
          if (this.LabelItem.labelType == 2 || this.LabelItem.labelType == 3) {
            this.LabelItem.strExtendContent.split(",").forEach((ele, index) => {
              let item = {
                id: index + 1,
                name: ele,
              };
              this.LabelItem.extendContent.push(item);
            });
          }
          let labelTypeText = "";
          switch (this.LabelItem.labelType) {
            case 1:
              labelTypeText = "数值标签：";
              break;
            case 2:
              labelTypeText = "单选标签：";
              break;
            case 3:
              labelTypeText = "多选标签：";
              break;
            case 4:
              labelTypeText = "文本标签：";
              break;
          }
          this.LabelItem.labelTypeText = labelTypeText;
          this.items.child_label.push(JSON.parse(JSON.stringify(this.LabelItem)));
          this.getDiffData();
          this.labelModal = false;
        } else {

          return false;
        }
      });
    },
    removeLabel (label) {
      this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let idx = this.items.child_label.findIndex(x => x === label);
        this.items.child_label.splice(idx, 1);
        this.$emit("change-label", this.items, this.curDepth);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    changeLable (payload, depth, idx) {
      this.$emit("change-label", payload);
    },
    // 递归校验是否必填
    check_switch (val) {
      if (val.labelType == 0) {
        if (val.child_label.length > 0) {
          val.child_label.map(item => {
            if (item.labelType == 0) {
              item.switch_value = val.switch_value
              if (item.child_label.length > 0) {
                this.check_switch(item)
              }
            }
          })
        }
      }
      return val
    },
  },
  created () {
    this.init();
  },
  mounted () { },
};
</script>

<style lang='less' scoped>
@import '../../style/mouse.less';
.class-0 {
  border: 1px solid #ccc;
  border-left: 1px solid #f90;
  padding-left: 20px;
}
.class-1 {
  margin: 10px 50px;
  border: 1px solid #ccc;
  border-left: 1px solid #e13d13;
  padding-left: 20px;
}
.class-2 {
  margin: 10px 50px;
  border: 1px solid #ccc;
  border-left: 1px solid #18b566;
  padding-left: 20px;
}
.label-level-container {
  margin-bottom: 20px;
  .el-form-item {
    margin: 0;
    padding: 20px 0;
    position: relative;
    .el-icon-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}
.label-level-container .ivu-form-item {
  position: relative;
  margin: 10px 0 !important;
}
.label-level-container label {
  margin-bottom: 0;
}
.ivu-form-item .icon-remove {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 20px;
}
.label-ui {
  /* display: flex;
  align-items: center;
  height: 50px; */
  border: 1px solid #ccc;
  border-left: 1px solid #2b85e4;
  margin: 10px 50px 10px 0;
  padding-left: 20px;
  border-radius: 3px;
}
.label-ui.class-1 {
  margin-left: 0;
}
.el-form-item-max {
  /deep/.el-form-item {
    display: inline-block;
  }
  /deep/.el-form-item {
    padding: 0;
  }
}
</style>